import React from 'react';
import { useCountdown } from './hooks/useCountdown';
import DateTimeDisplay from './DateTimeDisplay';

const ExpiredNotice = () => {
    return (
        <div className="expired-notice">
        <span>Expired!!!</span>
        <p>Please select a future date and time.</p>
        </div>
    );
};
  
const ShowCounter = ({ days, hours, minutes }) => {
return (
    <div className="show-counter">
        <div className="timer-container">
          <div className="timer-box">
            <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 3} />
          </div>
          <span className="timer-separator">:</span>
          <div className="timer-box">
            <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
          </div>
          <span className="timer-separator">:</span>
          <div className="timer-box">
            <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
          </div>
        </div>
    </div>
);
};

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
      />
    );
  }
};

export default CountdownTimer;