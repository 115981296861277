import './App.css';
import CountdownTimer from './CountdownTimer';

function App() {

  const THREE_DAYS_IN_MS = 12 * 24 * 60 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();

  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;

  return (
    <div className="App">
      <header className="App-header">
        <div className="circle1"></div>
        <div className="circle2"></div>
        <h1 className="Coming-soon">Coming Soon</h1>
          <CountdownTimer targetDate={dateTimeAfterThreeDays}/>
        <p className='Powered-by'>Powered By</p>
        <img src="/logo-keranala-1.svg" alt="Keranala Logo" className='keranala-logo-1'/>
      </header>
    </div>
  );
}

export default App;